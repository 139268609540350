/**
 * 登录状态管理
 */
export default {
    namespaced: true,
    state: {
        handleTimeController:true,//操作控制器
        handleTimeAt: 0,//操作计时
        exmaAt:0,//考试时间
        exmaAtController:true,
    },
    mutations: {
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },

    },
    actions: {
        //清除操作时间
        clearHandleTime({ commit, state, dispatch }, key) {
            commit('SET', { key: 'handleTimeController', value:  false});
            commit('SET', { key: 'handleTimeAt', value:  0});
        },
        //设置操作时间/重新开始操作
        setHandleTime({ commit, state, dispatch }, key) {
            commit('SET', { key: 'handleTimeController', value:  true});
            commit('SET', { key: 'handleTimeFn', value:  setHandleTimeFunction(commit, state)});
        },
        //停止操作时间
        stopHandleTime({ commit, state }, key) {
            commit('SET', { key: 'handleTimeController', value:  false});
        },
        //考试
        exmaStart({ commit, state }, key) {
            commit('SET', { key: 'exmaAtController', value:  true});
            commit('SET', { key: 'exmaStartFn', value:  exmaStartFunction(commit, state)});
        },
        //停止考试时间
        exmaStop({ commit, state }, key) {
            commit('SET', { key: 'exmaAt', value:  0});
            commit('SET', { key: 'exmaAtController', value:  false});
        },

    }
}

function exmaStartFunction(commit,state){
    if(!state.exmaAtController)return;
    commit('SET', { key: 'exmaAt', value: state.exmaAt+=1 });
    return setTimeout(()=>{
        exmaStartFunction(commit,state);
    },1000);
}

function setHandleTimeFunction(commit,state){
    if(!state.handleTimeController)return;
    commit('SET', { key: 'handleTimeAt', value: state.handleTimeAt+=1 });
    return setTimeout(()=>{
        setHandleTimeFunction(commit,state);
    },1000);
}


