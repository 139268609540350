/**
 * 框架全局配置
 */
 export default {
    // 不需要登录的路由
    whiteList: ['/login',],
    // token传递的header名称
    tokenHeaderName: 'Authorization',
    // token存储的名称
    tokenStoreName: 'access_token',
    // 用户信息存储的名称
    userStoreName: 'user',
    /**
     * 获取缓存的token的方法
     * @returns {string}
     */
    takeToken() {
      let token = localStorage.getItem(this.tokenStoreName);
      if (!token) {
        token = sessionStorage.getItem(this.tokenStoreName);
      }
      return token;
    },
    /**
     * 缓存token的方法
     * @param token
     * @param remember 是否永久存储
     */
    cacheToken(token, remember) {
      localStorage.removeItem(this.tokenStoreName);
      sessionStorage.removeItem(this.tokenStoreName);
      if (token) {
        if (remember) {
          localStorage.setItem(this.tokenStoreName, token);
        } else {
          sessionStorage.setItem(this.tokenStoreName, token);
        }
      }
    },
    /**
     * 获取缓存的用户信息
     * @returns {object}
     */
    takeUser() {
      try {
        return JSON.parse(localStorage.getItem(this.userStoreName)) || {};
      } catch (e) {
        console.error(e);
      }
      return {};
    },
    /**
     * 缓存用户信息
     * @param user
     */
    cacheUser(user) {
      if (user) {
        localStorage.setItem(this.userStoreName, JSON.stringify(user));
      } else {
        localStorage.removeItem(this.userStoreName);
      }
    }
  }
  