/**
 * 登录状态管理
 */
 export default {
    namespaced: true,
    state: {
        qidao_huanzhuangruangu:false,
        qidao_yangqimianzhao:false,
        qidao_lma:false,
        houjing:false,
        guangxianhoujing:false,
        buyeForm:{},
        jianhuyiController:'',
        nbpStart:false,
        nbpValue:[],
        nbpAt:180,
        nbpAtController:false,
    },
    mutations: {
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },

    },
    actions: {
        setQidao({commit, state}, key) {
            commit('SET', {key: key, value: !state[key]});
        },
        resetYangqi({commit,statte},key){
            commit('SET', {key: 'qidao_yangqimianzhao', value: false});
        },
        setBuye({commit, state}, key={}){
            commit('SET', {key: 'buyeForm', value: key});
        },
        setJianhuyi({commit, state}, key=''){
            commit('SET', {key: 'jianhuyiController',value: key});
        },
        nbpRun({commit, state, rootGetters,dispatch},key){
            if(rootGetters.cuffList.length == 0 || state.nbpStart)return;
            commit('SET',{key: 'nbpStart',value: true});
            commit('SET',{key: 'nbpValue',value: JSON.parse(JSON.stringify(rootGetters.cuffList)) || []});
            nbpStart(commit,state,dispatch);
        },
        nbpTimeStart({commit,state,dispatch,rootGetters},key){
            commit('SET',{key: 'nbpAtController',value: true});
            commit('SET',{key: 'nbpAt',value: 180});
            nbpTimeStartFunc(commit,state,dispatch);
        },
        clearController({commit, state}, key) {
            commit('SET', {key: 'qidao_huanzhuangruangu', value: false});
            commit('SET', {key: 'qidao_yangqimianzhao', value: false});
            commit('SET', {key: 'qidao_lma', value: false});
            commit('SET', {key: 'houjing', value: false});
            commit('SET', {key: 'guangxianhoujing', value: false});
            commit('SET', {key: 'buyeForm', value: {}});
            commit('SET', {key: 'jianhuyiController', value: ''});
            commit('SET', {key: 'nbpStart', value: false});
            commit('SET', {key: 'nbpValue', value: []});
            commit('SET', {key: 'nbpAt', value: 180});
            commit('SET', {key: 'nbpAtController', value: false});
        },
    }
}
function nbpTimeStartFunc(commit,state,dispatch){
    if(state.nbpStart || !state.nbpAtController)return;
    if( state.nbpAt <= 1 ){
        dispatch('nbpRun');
        return;
    }
    commit('SET',{key: 'nbpAt',value: state.nbpAt-=1});
    setTimeout(() => {
        nbpTimeStartFunc(commit,state,dispatch);
    }, 1000);
}

function nbpStart(commit,state,dispatch){
    let _n = state.nbpValue;
    if(_n.length <= 1){
        commit('SET',{key: 'nbpStart',value: false});
        dispatch('nbpTimeStart');
        return
    };
    _n.splice(0,1);
    commit('SET',{key: 'nbpValue',value: _n || []});
    setTimeout(()=>{
        nbpStart(commit,state,dispatch);
    },1000);
}

