import { createRouter, createWebHistory } from 'vue-router'
import setting from '@/config/setting';
import store from '../store';

const routes = [
  {
    path: '/',
    name: 'index',
    meta:{
      type:'index'
    },
    component: () => import('../views/Index/Index.vue')
  },
  {
    path: '/login',
    name: 'login',
    component: () => import('../views/Login/Index.vue')
  },
  {
    path: '/practice',//练习
    name: 'practice',
    component: () => import('../views/Answer/Practice.vue'),

  },
  {
    path: '/exma',//考试
    name: 'exma',
    component: () => import('../views/Answer/Exma.vue'),

  },
  {
    path: '/home',//首页 (基础练习,练习任务,考核任务)
    name: 'home',
    component: () => import('../views/Home/Index.vue'),
    meta:{
      type:'index'
    },
    redirect:'/home/my-basic-practise',
    children:[
      {
        path: 'my-basic-practise',//我的基础练习
        name: 'MyBasicPractise',
        component: () => import('../views/Home/BasicPractise.vue'),
      },
      {
        path: 'my-practise-task',//我的练习任务
        name: 'MyPractiseTask',
        component: () => import('../views/Home/PractiseTask.vue'),
      },
      {
        path: 'my-assessment-task',//我的考核任务
        name: 'MyAssessmentTask',
        component: () => import('../views/Home/AssessmentTask.vue'),
      },
    ],
  },
  {
    path: '/study',//学习记录与统计
    name: 'study',
    component: () => import('../views/Study/Index.vue'),
    meta:{
      type:'study'
    },
    redirect:'/study/basic-practise-table',
    children:[
      {
        path: 'basic-practise-table',//我的基础练习
        name: 'BasicPractiseTable',
        component: () => import('../views/Study/BasicPractiseTable.vue'),
    
      },
      {
        path: 'practise-task-table',//练习任务记录
        name: 'PractiseTaskTable',
        component: () => import('../views/Study/PractiseTaskTable.vue'),
    
      },
      {
        path: 'assessment-task-table',//考核任务记录
        name: 'AssessmentTaskTable',
        component: () => import('../views/Study/AssessmentTaskTable.vue'),
    
      },
    ],
  },
  {
    path: '/userinfo',//个人中心（个人资料，账号设置）
    name: 'userinfo',
    component: () => import('../views/UserInfo/Index.vue'),
    meta:{
      type:'userinfo'
    },
    redirect:'/userinfo/index',
    children:[
      {
        path: 'index',//我的基础练习
        name: 'UserInfoIndex',
        component: () => import('../views/UserInfo/UserInfoIndex.vue'),
      },
      {
        path: 'setting',//我的练习任务
        name: 'UserInfoSetting',
        component: () => import('../views/UserInfo/UserInfoSetting.vue'),
      },
    ],
  },
  {
    path: '/about',//使用帮助
    name: 'about',
    component: () => import('../views/About/Index.vue'),
    meta:{
      type:'about'
    },
    redirect:'/about/help',
    children:[
      {
        path: 'help',//使用帮助
        name: 'AboutHelp',
        component: () => import('../views/About/AboutHelp.vue'),
      },
    ],
  },
  {
    path: "/:catchAll(.*)",
    redirect: "/",
  }
]

const router = createRouter({
  history: createWebHistory(),
  routes
})

router.beforeEach((to, from, next) => {
  // next();
  // 判断是否登录
  if (setting.takeToken()) {
    next();
  } else if (setting.whiteList.includes(to.path)) {
    next();
  } else {
    next({ name: 'login', query: to.path === '/' ? {} : { from: to.path } });
  }
});


export default router
