/**
 * 登录状态管理
 */
 export default {
   namespaced: true,
   state: {
     // 主题风格: true light(亮色),false dark(暗色)
    layoutStyle: false,
   },
   mutations: {
     // 修改值
     SET(state, obj) {
       state[obj.key] = obj.value;
     },
     
   },
   actions: {
    setLayoutStyle({commit, state}, key) {
      commit('SET', {key: key, value: !state[key]});
    },
   }
 }
 