/**
 * axios配置
 */
import axios from 'axios';
import store from '../store';
import router from '../router';
import setting from './setting';
import { Modal,message } from 'ant-design-vue';

// 设置统一的url
axios.defaults.baseURL = process.env.VUE_APP_API_BASE_URL;
axios.defaults.withCredentials = true;
/* 请求拦截器 */
axios.interceptors.request.use((config) => {
  // 添加token到header
  const token = setting.takeToken();
  const user = setting.takeUser();
  if(Object.values(user).length != 0){
    config.headers['Class-id'] = user.classInfo[store.getters.classIndex].classId;
    config.headers['Course-id'] = user.classInfo[store.getters.classIndex].courseId;
  }
  if (token) {
    config.headers[setting.tokenHeaderName] = token;
  }
  return config;
}, (error) => {
  return Promise.reject(error);
});

/* 响应拦截器 */
axios.interceptors.response.use((res) => {
  // 登录过期处理
  if (res.data.code === 401) {
    Modal.destroyAll();
    Modal.info({
      title: '系统提示',
      content: '登录状态已过期, 请退出重新登录!',
      okText: '重新登录',
      onOk: () => {
        goLogin(true);
      }
    });
    return Promise.reject(new Error(res.data.msg));
  }
  
  if(res.data.code != 0){
    message.error(res.data.msg);
    return Promise.reject(res.data);
  }
  // token自动续期
  const access_token = res.headers[setting.tokenHeaderName];
  if (access_token) {
    setting.cacheToken(access_token);
  }
  return res;
}, (error) => {
  return Promise.reject(error);
});

/**
 * 跳转到登录页面
 */
function goLogin(reload) {
  store.dispatch('user/removeToken').then(() => {
    if (reload) {
      location.replace('/login');  // 这样跳转避免再次登录重复注册动态路由
    } else {
      const path = router.currentRoute.path;
      return router.push({
        path: '/login',
        query: path && path !== '/' ? { form: path } : null
      });
    }
  });
}
