// import 'core-js/stable';
// import 'regenerator-runtime/runtime';
import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Antd from 'ant-design-vue';
import axios from 'axios';
import VueAxios from 'vue-axios';
import './config/axios-config';
import 'ant-design-vue/dist/antd.css';
import './assets/css/reset.css'
import './assets/css/global.scss'
import _interface from "./config/interface"
// import XLSX from "xlsx";


const app = createApp(App);
app.config.globalProperties.$interface = _interface;
app.use(VueAxios, axios);
app.use(store).use(router).use(Antd).mount('#app');
