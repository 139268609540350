/**
 * 登录状态管理
 */
 import setting from '@/config/setting';
 
 export default {
   namespaced: true,
   state: {
     // 当前用户信息
     user: setting.takeUser(),
     classIndex:0,
   },
   mutations: {
     // 修改值
     SET(state, obj) {
       state[obj.key] = obj.value;
     },
     // 修改token
     SET_TOKEN(state, obj) {
       setting.cacheToken(obj.token, obj.remember);
       state.token = obj.token;
       if (!obj.token) {
         setting.cacheUser();
       }
     }
   },
   actions: {
     /**
      * 缓存token
      * @param commit
      * @param token {String, {token: String, remember: String}}
      */
     setToken({commit}, token) {
       let remember = true;
       if (typeof token === 'object') {
         remember = token.remember;
         token = token.token;
       }
       commit('SET_TOKEN', {token: token, remember: remember});
     },
     /**
      * 移除token
      * @param commit
      */
     removeToken({commit}) {
       commit('SET_TOKEN', {});
     },
     /**
      * 设置用户信息
      * @param commit
      * @param user {Object} 用户信息
      */
     setUser({commit}, user) {
       setting.cacheUser(user);
       commit('SET', {key: 'user', value: user});
     },
     setclassIndex({commit}, key) {
      commit('SET', {key: 'classIndex', value: key});
    },
     
    
   }
 }
 