/**
 * 考试数据管理
 */
import axios from 'axios';
import _interface from '../../config/interface.js';
import { message, notification } from 'ant-design-vue';
export default {
    namespaced: true,
    state: {
        //是否结束
        isEnd: false,
        waveList: [],
        //病人 姿势
        patientPositionDict: [],
        //病人 控制温度
        controlTemperature: [],
        //步骤类型
        stepTypeList: [],
        //药物列表
        drugData: [],
        //药物列表 用药
        drug1Data: [],
        //科室
        department: [],
        //步骤
        caseSteps: [],
        //下一步数据
        stepInfo: {},
        //日志
        caseLog: [],
        //初始答题数据
        initData: {},
        //当前分数
        score: 0,
        //右侧基础数据列表
        vitalSignsList: [],
        vitalSigns: {},
        //cuff
        cuffList: [],

    },
    mutations: {
        // 修改值
        SET(state, obj) {
            state[obj.key] = obj.value;
        },

    },
    actions: {
        clearInfo({ commit, state }, key) {
            commit('SET', { key: 'initData', value: {} });
            commit('SET', { key: 'isEnd', value: false });
            commit('SET', { key: 'score', value: 0 });
            commit('SET', { key: 'caseLog', value: [] });
            commit('SET', { key: 'stepInfo', value: {} });
            commit('SET', { key: 'vitalSigns', value: {} });
            commit('SET', { key: 'vitalSignsList', value: [] });
            commit('SET', { key: 'cuffList', value: [] });

        },
        submitStep({ dispatch, commit, state, rootState, rootGetters, actions, getters }, postData) {
            return new Promise((resolve, reject) => {
                // const hide = message.loading("请求中..", 0);
                axios.post(_interface.exmaNext, postData).then((res) => {
                    // hide();
                    if (!res.data.data) return;
                    let data = res.data.data || {};
                    let timeStepInfo = data.stepInfo || {};
                    setTimeout(() => {
                        //结束
                        if (data.isEnd == 1) {
                            commit('SET', { key: 'isEnd', value: true });
                            // return;
                        }
                        //stepInfo type 为 hide 则递归调用 
                        if (data.stepInfo && data.stepInfo.stepType == 'hide') {
                            let _d = Object.assign({}, postData);
                            _d.actionTime = rootGetters.getHandleTimeAt;
                            _d.component = 'hide';
                            dispatch('submitStep', _d);
                        }
                        //isRight 0答错 1答对
                        if (data.isRight == 0) {
                            commit('SET', { key: 'score', value: parseInt(state.score - (data.score || 0)) });
                            notification.error({
                                message: '温馨提示',
                                description:
                                    `当前操作步骤错误,操作得分-${data.score}`,
                                duration: null,
                            });
                            // actions.getExmaActionLog();
                        } else {
                            let _i = state.caseSteps.findIndex((item) => item.check != true);
                            let caseSteps = state.caseSteps;
                            caseSteps[_i]['check'] = true;
                            commit('SET', { key: 'caseSteps', value: caseSteps });
                            commit('SET', { key: 'score', value: parseInt(state.score + (data.score || 0)) });
                            commit('SET', { key: 'stepInfo', value: data.stepInfo || {} });
                            if (timeStepInfo.vitalSignsArray && timeStepInfo.vitalSignsArray.length != 0) {
                                commit('SET', { key: 'vitalSignsList', value: timeStepInfo.vitalSignsArray || [] });
                                vitalSignsStart(commit, state);
                            }
                            let _caseLog = state.caseLog || [];
                            let updateCaseLog = data.caseLog || [];
                            _caseLog.unshift(...updateCaseLog) || [];
                            commit('SET', { key: 'caseLog', value: _caseLog });
                        }
                    }, (timeStepInfo.waitTime != 0 ? timeStepInfo.waitTime + 1 : 0) * 1000);
                    if (data.stepInfo != null) {
                        localStorage.setItem("StopsT", JSON.stringify('Yes'));
                        if (data.stepInfo.patientDataArray && data.stepInfo.patientDataArray.length > 0) {
                            let patientDataArrayT = []
                            localStorage.setItem("patientDataArray", JSON.stringify(res.data.data.stepInfo.patientDataArray));
                            let timessT = -1
                            let time2 = setInterval(() => {
                                patientDataArrayT = []
                                timessT += 1
                                let arrs = JSON.parse(localStorage.getItem('patientDataArray'))
                                if (timessT >= res.data.data.stepInfo.patientDataArray.length) {
                                    clearInterval(time2);
                                    return
                                    timess -= 1
                                }
                                patientDataArrayT.push(arrs[timessT])
                                localStorage.setItem("patientDataArrayT", JSON.stringify(patientDataArrayT));
                            }, 1000);
                        }


                        if (data.stepInfo.checkDataArray && data.stepInfo.checkDataArray.length > 0) {
                            localStorage.setItem("checkDataArray", JSON.stringify(res.data.data.stepInfo.checkDataArray));
                        }
                        let timess = 0
                        let time1 = setInterval(() => {
                            timess += 1
                            if (timess >= res.data.data.stepInfo.checkDataArray.length || JSON.parse(localStorage.getItem('Stops')) == 'Yes') {
                                clearInterval(time1);
                                timess -= 1
                            }
                            console.log(timess, 'TimesTimes')
                            localStorage.setItem("Times", JSON.stringify(timess));
                        }, 1000);



                        // localStorage.setItem("Stops", JSON.stringify('NO'));
                        // let timess=0
                        // let time1 =  setInterval(() => {
                        //     timess+=1
                        //     if (timess>=res.data.data.stepInfo.checkDataArray.length || JSON.parse(localStorage.getItem('Stops'))=='Yes') {
                        //         clearInterval(time1);
                        //         timess-=1
                        //     }
                        //     localStorage.setItem("Times", JSON.stringify(timess));
                        // }, 1000);
                    }

                    resolve(data.isRight);
                }).catch(e => {
                    // hide();
                    reject(e);
                });
            });
        },
        setExmaData({ commit, state }, key) {
            commit('SET', { key: 'initData', value: key });
            commit('SET', { key: 'caseSteps', value: key.caseSteps || [] });
            commit('SET', { key: 'stepInfo', value: key.stepInfo || {} });
            commit('SET', { key: 'caseLog', value: key.caseLog || {} });
            commit('SET', { key: 'cuffList', value: key.cuff.split(',').filter(item => item && item.trim()) || [] });
            let _stepInfo = key.stepInfo || {};
            if (_stepInfo.vitalSignsArray && _stepInfo.vitalSignsArray.length != 0) {
                commit('SET', { key: 'vitalSignsList', value: _stepInfo.vitalSignsArray || [] });
                vitalSignsStart(commit, state);
            }

        },
        setStepType({ commit, state }, key) {
            commit('SET', { key: 'stepTypeList', value: key });
        },
        setDrugData({ commit, state }, key) {
            let d = key.map(item => {
                item.bolusOptions = item.bolusOptions.split(',');
                item.infusionOptions = item.infusionOptions.split(',');
                return item;
            });
            commit('SET', { key: 'drugData', value: key });
        },
        setDrug1Data({ commit, state }, key) {
            let d = key.map(item => {
                item.bolusOptions = item.bolusOptions.split(',');
                item.infusionOptions = item.infusionOptions.split(',');
                return item;
            });
            commit('SET', { key: 'drug1Data', value: key });
        },
        setPatientPositionDict({ commit, state }, key) {
            commit('SET', { key: 'patientPositionDict', value: key });
        },
        setControlTemperature({ commit, state }, key) {
            commit('SET', { key: 'controlTemperature', value: key });
        },
        setDepartment({ commit, state }, key) {
            commit('SET', { key: 'department', value: key });
        },
        setWave({ commit, state }, key) {
            commit('SET', { key: 'waveList', value: key });
        },
        closeIsEndModel({ commit, state }, key) {
            commit('SET', { key: 'isEnd', value: false });
        }

    }
}

function vitalSignsStart(commit, state) {
    if (state.vitalSignsList.length == 0) return;
    let a = state.vitalSignsList.splice(0, 1) || [];
    commit('SET', { key: 'vitalSigns', value: a[0] || {} });
    setTimeout(() => {
        vitalSignsStart(commit, state);
    }, 1000);
}