<template>
<a-config-provider :locale="locale">
    <router-view />
  </a-config-provider>
  
</template>
<script>
import zhCN from 'ant-design-vue/lib/locale-provider/zh_CN';
export default {
  data() {
      return {
        locale: zhCN,
      };
    },
  watch:{
    $route(to, from) {
      // console.log(to,from)
    }
  },
}
</script>
<style lang="scss">
#app {
  height: 100%;
}
</style>
