 import {createStore} from 'vuex';
 import getters from './getters';
 import user from './modules/user';
 import theme from './modules/theme';
 import answer from './modules/answer';
 import exma from './modules/exma';
 import controller from './modules/controller';
 
 export default createStore({
   state: {},
   mutations: {},
   actions: {},
   modules: {
     user,
     theme,
     answer,
     exma,
     controller
   },
   getters
 });
 