import { setAttribute } from "echarts/lib/util/model";

export default {
  classIndex: state => state.user.classIndex,
  userInfo: state => state.user.user || {},
  classInfo: state => state.user.user.classInfo[state.user.classIndex] || {},
  isEnd: state => state.exma.isEnd,
  user: state => state.user,
  theme: state => state.theme,
  layoutStyle: state => state.theme.layoutStyle,
  getHandleTimeAt: state => state.answer.handleTimeAt,
  handleTimeController: state => state.answer.handleTimeController,
  exmaAt: state => {
    let endTime = state.answer.exmaAt;
    let secondTime = parseInt(endTime)
    let min = 0
    let h = 0
    let result = ''
    if (secondTime > 60) {
      min = parseInt(secondTime / 60)
      secondTime = parseInt(secondTime % 60)
      if (min > 60) {
        h = parseInt(min / 60)
        min = parseInt(min % 60)
      }
    }
    result = `${h.toString().padStart(2, '0')}:${min.toString().padStart(2, '0')}:${secondTime.toString().padStart(2, '0')}`
    return result

  },
  //药物列表 首页
  drugData: state => state.exma.drugData || [],
  //药物列表 用药
  drug1Data: state => state.exma.drug1Data || [],
  //模式 test=基础练习,exercise=练习,assess=考核
  missionType: state => state.exma.initData.missionType,
  //步骤类型
  stepType: state => state.exma.stepTypeList,
  //步骤
  caseSteps: state => state.exma.caseSteps,
  //总分
  totalScore: state => state.exma.initData.totalScore || 0,
  //当前分数
  score: state => state.exma.score || 0,
  //下一步提示 只有基础练习会返回
  stepInfo: state => state.exma.stepInfo || {},
  //下一步提示 只有基础练习会返回
  stepInfoVitalSigns: state => {
    // let stepInfo = state.exma.stepInfo || {}
    // return stepInfo.vitalSigns || {}
    return state.exma.vitalSigns || {}
  },
  //案例名称	
  caseName: state => state.exma.initData.caseName || '',
  //案例号
  caseNumber: state => state.exma.initData.caseNumber || '',
  //案例基础信息
  caseInfo: state => state.exma.initData.caseInfo || '',
  //日志		
  caseLog: state => state.exma.caseLog || '',
  //病人 姿势		
  patientPositionDict: state => state.exma.patientPositionDict || [],
  //病人 控制温度		
  controlTemperature: state => state.exma.controlTemperature || [],
  //心电图数据
  waveList: state => state.exma.waveList || [],
  //检验 科室		
  department: state => state.exma.department || [],
  memberCaseId: state => state.exma.initData.memberCaseId,
  //气道操作
  qidaoHuanzhuangruangu: state => state.controller.qidao_huanzhuangruangu || false,
  //监护仪选项
  jianhuyiDisplay: state => state.controller.jianhuyiController || '',
  //血压 nbpcount
  nbpAt: state => state.controller.nbpAt || 180,
  nbpValue: state => state.controller.nbpValue || [],
  nbpStart: state => state.controller.nbpStart || false,
  cuffList: state => state.exma.cuffList || [],
  qidaoYangqimianzhao: state => state.controller.qidao_yangqimianzhao || false,
  qidaoLma: state => state.controller.qidao_lma || false,
  buyeSelectForm: state => state.controller.buyeForm || {},
  houjing: state => state.controller.houjing || false,
  guangxianhoujing: state => state.controller.guangxianhoujing || false,
  //获取心电图
  getWaveChart1: state => {
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo.ecg2;
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
  getWaveChart2: state => {
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo.ecgv5;
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
  getWaveChart3: state => {
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo.spo2;
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
  getWaveChart4: state => {
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo.co2;
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
  getWaveChart5: state => {
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo.spirometer;
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
  getWaveChart6: state => {
    let idKey = {
      'display_abp': 'abp',
      'display_pap': 'pap',
      'display_cvp': 'cvp',
      'display_pawp': 'pawp',
      'cardiac_output': 'cardiac_output',
    };

    if (state.controller.jianhuyiController != 'display_abp' && state.controller.jianhuyiController != 'display_pap' && state.controller.jianhuyiController != 'display_cvp' && state.controller.jianhuyiController != 'display_pawp'&& state.controller.jianhuyiController != 'cardiac_output') return [];
    let waveList = state.exma.waveList;
    let ecg = state.exma.vitalSigns.ecg || 90;
    let waveInfo = state.exma.stepInfo.waveInfo;
    if (!waveInfo) return [];
    let id = waveInfo[idKey[state.controller.jianhuyiController]];
    let chart = waveList.find(item => item.waveId == id);
    if (!chart) return [];
    return getWaveData({ecg,chart});
  },
}

function getWaveData({ecg,chart}) {
  let waveData = JSON.parse(chart.waveData);
  console.log('jin');
  if (ecg <= 60) {
    return waveData.wave1.split(',').map(item => Number(item.trim()));
  }
  if (ecg > 60 && ecg <= 80) {
    return waveData.wave2.split(',').map(item => Number(item.trim()));
  }
  if (ecg > 80 && ecg <= 100) {
    return waveData.wave3.split(',').map(item => Number(item.trim()));
  }
  if (ecg > 100 && ecg < 120) {
    return waveData.wave4.split(',').map(item => Number(item.trim()));
  }
  if (ecg >= 120) {
    return waveData.wave5.split(',').map(item => Number(item.trim()));
  }
  return [];
}
