const type = 'rest-api/';
const admin = 'api/admin/'
const api = {
    loginImageCode: type+'login/image-code',//获取图像验证码
    loginSendSms: type+'login/sendSms',//发送短信验证码
    loginMobile: type+'login/mobile',//手机号登录
    loginPassword: type+'login/password',//密码登录
    memberModifyPassword:type+'member/modify-password',//修改密码
    memberModifyPasswordSendSms:type+'member/modify-password/sendSms',//修改密码验证码
    memberClassInfo:type+'member/classInfo',//获取登录时的班级信息
    homeCount:type+'home/count',//首页统计
    examStart: type+'exam/start',//开始练习
    dictList: type+'dict/list',//获取下拉选择等数据
    drugList: type+'drug/list',//药物列表
    examActionLog: type+'exam/actionLog',//操作记录
    caseLogList: type+'caseLog/list',//完整日志
    exmaNext: type+'exam/next',//下一步
    myExercise: type+'my/exercise',//我的基础练习
    dictListDepartment: type+'dict/list/department',//专业科目列表
    myExerciseSelect: type+'my/exercise/select',//我的基础练习案例下拉选项
    myExerciseKeywordsSelect: type+'my/exercise/keywords-select',//我的基础练习 关键词下拉选择
    myMission:type+'mission',//我的考核/练习任务
    missionSelect:type+'mission/select',//我的练习任务下拉选项
    waveList:type+'wave/list',//波形列表
    missionLog:type+'mission/log',//练习/任务学习记录
    caseWrongCaseLog:type+'caseLog/wrong-list',//错误日志
    myGetHelp:type+'my/getHelp',//获取帮助信息
    examCheckStartCount:type+'exam/checkStartCount',//检测是否可以考试
}

/**
 * key 对应数据：

hospital_version     医院版本
department             科室
age_type                 年龄类型
step_type                 步骤分类
operation_phase      手术阶段
componet_object     方法工具
weight_type              体重类型
patient_position_dict 病人姿势
control_temperature 控制温度

练习中涉及的数据有：病人姿势，温度控制，手术阶段

方法工具用于确认每一个操作对应的componet值是什么

用药的component 是 drug~~~~
 */

export default api;